import { createSagas } from "redux-box";
import userApi from "@s/api/user";
import { call, put } from "redux-saga/effects";
//import {push} from "react-router-redux";
import swal from "sweetalert2";
import * as XLSX from "xlsx";

const errorHandler = (err) => {
  //console.log(err);
  swal("Oops!", err.error_message, "error");
};

const validate = (name) => {
  let n = name.trim();
  const re = /^[a-zA-Z0-9_ .]*$/;
  if (re.test(n) == true && name.length > 2) return true;
  return false;
};

const handleDownload = (data) => {
    let fileName = "errors";

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Write the workbook to a binary string
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });

  // Create a Blob from the binary string
  const blob = new Blob([excelBuffer], {
    type: "application/octet-stream",
  });

  // Create a link element
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}.xlsx`;

  // Programmatically click the link to trigger the download
  document.body.appendChild(a);
  a.click();

  // Clean up and remove the link
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export default createSagas({
  //SAGA TO GET PARENT LIST
  REQUEST_PARENTS: function*() {
    try {
      let search = "";
      const globalState = require("store").default.getState();
      let offset = globalState.user.parents_offset;
      let filterParent = globalState.user.filterParentSearch;
      let parent_search = globalState.user.search_parent;
      let val = 0;
      let count = 0;
      for (let i in filterParent) {
        if (filterParent[i] !== "") count++;
      }

      for (let i in filterParent) {
        if (filterParent[i] !== "") {
          if (count === 1) {
            search += i + "=" + filterParent[i];
            break;
          } else {
            search += i + "=" + filterParent[i];
            if (val < count - 1) search += "&";
            val++;
          }
        }
      }
      if (parent_search !== "") {
        if (count === 0) search += "key=" + parent_search;
        else search += "&key=" + parent_search;
      }
      yield put({
        type: "SET_FETCH_PARENT",
        value: true,
      });
      if (offset >= 0) {
        if (count > 0 || parent_search !== "") search += "&offset=" + offset;
        else search += "offset=" + offset;
      }

      const res = yield call(userApi.getAllParents, search);
      yield put({
        type: "SET_PARENTS",
        parents: res,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_PARENT",
        value: false,
      });
    }
  },
  REQUEST_PARENTS_BY_OFFSET: function*({ offset }) {
    try {
      yield put({
        type: "SET_PARENTS_OFFSET",
        offset: offset,
      });
      yield put({
        type: "REQUEST_PARENTS",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_PARENT_BY_ID: function*({ parentId }) {
    try {
      const res = yield call(userApi.getParentById, parentId);
      yield put({
        type: "SET_ACTIVE_PARENT",
        parent: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_UPDATE_PARENT_PROFILE: function*({ parent }) {
    try {
      const res = yield call(userApi.updateParentProfile, parent.id, parent);
      if (res) {
        swal("Done!", res.name + " profile updated successfully!", "success");
      }
      yield put({
        type: "REQUEST_PARENT_BY_ID",
        parentId: res.id,
      });
      yield put({
        type: "REQUEST_PARENTS",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CHILDREN_BY_OFFSET: function*({ offset }) {
    try {
      yield put({
        type: "SET_CHILDREN_OFFSET",
        offset: offset,
      });
      yield put({
        type: "REQUEST_CHILDREN",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CHILDREN: function*() {
    try {
      let search = "";
      const globalState = require("store").default.getState();
      let offset = globalState.user.children_offset;
      let filterChild = globalState.user.filterChildSearch;
      let child_search = globalState.user.search_child;
      let val = 0;
      let count = 0;
      for (let i in filterChild) {
        if (filterChild[i] !== "") count++;
      }

      for (let i in filterChild) {
        if (filterChild[i] !== "") {
          if (count === 1) {
            search += i + "=" + filterChild[i];
            break;
          } else {
            search += i + "=" + filterChild[i];
            if (val < count - 1) search += "&";
            val++;
          }
        }
      }
      if (child_search !== "") {
        if (count === 0) search += "key=" + child_search;
        else search += "&key=" + child_search;
      }
      if (offset >= 0) {
        if (count > 0 || child_search !== "") search += "&offset=" + offset;
        else search += "offset=" + offset;
      }
      yield put({
        type: "SET_FETCH_CHILD",
        value: true,
      });
      const res = yield call(userApi.getAllChildren, search);
      yield put({
        type: "SET_CHILDREN",
        children: res,
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_CHILD",
        value: false,
      });
    }
  },
  REQUEST_CHILDREN_BY_PARENT_ID: function*({ parentId }) {
    try {
      const res = yield call(userApi.getChildrenByParentId, parentId);
      yield put({
        type: "SET_CHILDREN",
        children: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CHILD_BY_ID: function*({ childId }) {
    try {
      const res = yield call(userApi.getChildById, childId);
      yield put({
        type: "SET_ACTIVE_CHILD",
        child: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_CHILD_PROFILE: function*({ child }) {
    try {
      const res = yield call(userApi.updateChildProfile, child.id, child);
      yield put({
        type: "SET_ACTIVE_CHILD",
        child: res,
      });
      yield put({
        type: "REQUEST_CHILD_BY_ID",
        childId: res.id,
      });
      yield put({
        type: "REQUEST_CHILDREN",
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_UPDATE_CHILD_SUB_LEVEL: function*({ child }) {
    try {
      const res = yield call(userApi.updateChildProfile, child.id, child);
      swal(
        "Done!",
        res.name + "'s level updated successfully to hard!",
        "success"
      );
      yield put({
        type: "REQUEST_CHILD_BY_ID",
        childId: res.id,
      });
      yield put({
        type: "REQUEST_CHILDREN",
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  EXPORT_PARENT_DATA: function*({ data }) {
    try {
      let search = "";
      const globalState = require("store").default.getState();
      //let offset = globalState.user.parents_offset;
      let filterParent = globalState.user.filterParentSearch;
      let parent_search = globalState.user.search_parent;
      let val = 0;
      let count = 0;
      for (let i in filterParent) {
        if (filterParent[i] !== "") count++;
      }

      for (let i in filterParent) {
        if (filterParent[i] !== "") {
          if (count === 1) {
            search += i + "=" + filterParent[i];
            break;
          } else {
            search += i + "=" + filterParent[i];
            if (val < count - 1) search += "&";
            val++;
          }
        }
      }
      if (parent_search !== "") {
        if (count === 0) search += "key=" + parent_search;
        else search += "&key=" + parent_search;
      }
      const res = yield call(userApi.exportData, data, search);
      if (res.success) {
        swal("Done!", "Parents data exported successfully!", "success");
        yield put({
          type: "HIDE_MODAL",
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  EXPORT_CHILD_DATA: function*({ data }) {
    try {
      let search = "";
      const globalState = require("store").default.getState();
      let filterChild = globalState.user.filterChildSearch;
      let child_search = globalState.user.search_child;
      let val = 0;
      let count = 0;
      for (let i in filterChild) {
        if (filterChild[i] !== "") count++;
      }

      for (let i in filterChild) {
        if (filterChild[i] !== "") {
          if (count === 1) {
            search += i + "=" + filterChild[i];
            break;
          } else {
            search += i + "=" + filterChild[i];
            if (val < count - 1) search += "&";
            val++;
          }
        }
      }
      if (child_search !== "") {
        if (count === 0) search += "key=" + child_search;
        else search += "&key=" + child_search;
      }

      const res = yield call(userApi.exportData, data, search);
      if (res.success) {
        swal("Done!", "Children data exported successfully!", "success");
        yield put({
          type: "HIDE_MODAL",
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_FREADOS_BY_CHILD_ID: function*({ data }) {
    try {
      const res = yield call(userApi.updateFreadosByChildId, data);
      if (res.success) {
        swal("Done!", "Freados Updated Successfully!", "success");
      }
      yield put({
        type: "REQUEST_CHILD_BY_ID",
        childId: data.child_id,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_STREAK_BY_CHILD_ID: function*({ data }) {
    try {
      const res = yield call(userApi.updateStreakByChildId, data);
      if (res.success) {
        swal("Done!", "Streak Updated Successfully!", "success");
      }
      yield put({
        type: "REQUEST_CHILD_BY_ID",
        childId: data.child_id,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  VALIDATE_LINK_SCHOOL: function*({ code }) {
    try {
      const res = yield call(userApi.validateSchool, code);
      // console.log(res)
      yield put({
        type: "SET_LINK_SCHOOL",
        school: res,
      });
    } catch (err) {}
  },
  GET_SECTIONS: function*({ data }) {
    // console.log(data)
    try {
      const res = yield call(userApi.getSections, data);
      // console.log(res)
      yield put({
        type: "SET_SECTIONS",
        data: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  LINK_SCHOOL_CODE: function*({ data }) {
    const child_id = data.child;
    try {
      const res = yield call(userApi.linkSchoolCode, data);
      // console.log(res)
      if (res) {
        swal("Done!", "School linked successfully.", "success");
        yield put({
          type: "REQUEST_CHILD_BY_ID",
          childId: child_id,
        });

        yield put({
          type: "SET_LINK_SCHOOL",
          school: null,
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  UNLINK_SCHOOL_CODE: function*({ data }) {
    // console.log(data)
    let child_id = data.child;
    try {
      const res = yield call(userApi.unlinkSchoolCode, data);
      if (res) {
        swal("Done!", "School unlinked successfully.", "success");

        yield put({
          type: "REQUEST_CHILD_BY_ID",
          childId: child_id,
        });
      }
      // console.log(res)
    } catch (err) {
      errorHandler(err);
    }
  },

  UPDATE_FREADOM_TEACHER: function*({ data }) {
    // console.log(data);
    let child_id = data.child;
    try {
      const res = yield call(userApi.updateFreadomTeacher, data);
      if (res) {
        swal("Done!", "Teacher updated successfully.", "success");
        yield put({
          type: "REQUEST_CHILD_BY_ID",
          childId: child_id,
        });
      }
    } catch (err) {
      yield put({
        type: "REQUEST_CHILD_BY_ID",
        childId: child_id,
      });
      errorHandler(err);
    }
  },
  CREATE_BULK_USER: function*({ data }) {
    console.log(data);
    const gState = require("store").default.getState();
    const grades = gState.activity.grades;
    let dataReceived = data;

    const errors = [];
    const usersCreated = [];
    let hasValidationErrors = false;

    try {
      yield put({
        type: "SET_LOADING",
        value: true,
      });
      for (const payload of dataReceived) {
        hasValidationErrors = false;
        let school = null;
        let grade = null;
        let sections = null;

        try {
          const validateName = validate(payload.name);
          if (!validateName) {
            errors.push({ payload, error: "Invalid name" });
            hasValidationErrors = true;
            // return;
          }

          if (payload.contact_no.length < 6 && payload.contact_no.length > 15) {
            errors.push({ payload, error: "Invalid contact number" });
            hasValidationErrors = true;
            // return;
          }

          if (!payload.grade) {
            errors.push({ payload, error: "Invalid grade" });
            hasValidationErrors = true;
            // return;
          }
          if (!payload.section) {
            errors.push({ payload, error: "Invalid section" });
            hasValidationErrors = true;
            // return;
          }

          if (payload.section) {
            payload.section = payload.section.split(",").map((s) => s.trim());
          }

          if (hasValidationErrors) {
            continue;
          }

          school = yield call(userApi.validateSchool, payload.schoolCode);

          if (school) {
            grade = grades.find(
              (grade) =>
                grade.name.toString().toLowerCase() ===
                payload.grade.trim().toLowerCase()
            );
            if (grade) {
              sections = yield call(userApi.getSections, {
                grade: grade.id,
                school: school.id,
              });

              let secArray = [];
              if (sections) {
                payload.section.forEach((section) => {
                  if (Object.values(sections).includes(section)) {
                    secArray.push(getKeyByValue(sections, section));
                  }
                });
                if (secArray.length === payload.section.length) {
                  payload.school = school.id;
                  payload.grade = grade.id;
                  payload.section = secArray;
                  payload.device_type = "web";

                  let res = yield call(userApi.registerUser, payload);
                  if (res) {
                    usersCreated.push(payload);
                  }
                } else {
                  let sectionsArray = payload.section.map(
                    (sec) => sections[sec]
                  );
                  errors.push({
                    payload: {
                      ...payload,
                      school: school.name,
                      grade: grade.name,
                      section: sectionsArray.join(", "),
                    },
                    error: "Invalid section",
                  });
                }
              } else {
                let sectionsArray = payload.section.map((sec) => sections[sec]);
                errors.push({
                  payload: {
                    ...payload,
                    school: school.name,
                    grade: grade.name,
                    section: sectionsArray.join(", "),
                  },
                  error: "Failed to fetch sections",
                });
              }
            } else {
              let sectionsArray = payload.section.map((sec) => sections[sec]);
              errors.push({
                payload: {
                  ...payload,
                  school: school.name,
                  grade: grade.name,
                  section: sectionsArray.join(", "),
                },
                error: "Invalid grade",
              });
            }
          } else {
            let sectionsArray = payload.section.map((sec) => sections[sec]);
            errors.push({
              payload: {
                ...payload,
                school: school.name,
                grade: grade.name,
                section: sectionsArray.join(", "),
              },
              error: "Invalid school code",
            });
          }
        } catch (error) {
          let sectionsArray = payload.section.map((sec) => sections[sec]);
          errors.push({
            payload: {
              ...payload,
              school: school.name,
              grade: grade.name,
              section: sectionsArray.join(", "),
            },
            error: error.error_message,
          });
        }
      }

      yield put({
        type: "SET_LOADING",
        value: false,
      });

      let errorsData = errors.map((err) => {
        return {
          ...err.payload,
          error: err.error,
        };
      });
      if (errors.length > 0) {
        swal(
          "Oops!",
          "There are some errors in the data. A file will get downloaded with all the errors.",
          "error"
        );
        handleDownload(errorsData);
      } else {
        swal("Success", "Users created successfully!", "success");
      }
    } catch (e) {}
  },
  VALIDATE_SCHOOL: function*({ code }) {
    try {
      const res = yield call(userApi.validateSchool, code);
      // console.log(res)
      yield put({
        type: "SET_SCHOOL",
        school: res,
      });
    } catch (err) {}
  },
  REGISTER_USER: function*({ payload }) {
    const globalState = require("store").default.getState();
    let parent = globalState.user.active_parent.id;
    try {
      yield put({
        type: "SET_USER_CREATED",
        value: false,
      });
      if (payload.add_child) {
        yield put({
          type: "SET_CREATING_CHILD",
          value: true,
        });
      }
      const res = yield call(userApi.registerUser, payload);
      // console.log(res)
      if (res) {
        if (payload.add_child) {
          yield put({
            type: "SET_CHILD_CREATED",
            value: true,
          });
          yield put({
            type: "SET_CREATING_CHILD",
            value: false,
          });
          yield put({
            type: "REQUEST_CHILDREN_BY_PARENT_ID",
            parentId: parent,
          });
          swal("Done!", "Child Added Successfully!", "success");

           yield put({
            type: "SET_CHILD_CREATED",
            value: false,
          });
        } else {
          swal("Done!", "User Registered Successfully!", "success");
          yield put({
            type: "SET_USER_CREATED",
            value: true,
          });
        }
        yield put({
          type: "SET_SCHOOL",
          school: null,
        });
      }
    } catch (err) {
      errorHandler(err);
      yield put({
        type: "SET_CREATING_CHILD",
        value: false,
      });
    }
  },
  UPDATE_BULK_CHILD: function*({ data }) {
    const gState = require("store").default.getState();
    const grades = gState.activity.grades;

    let dataArr = [];

    try {
      yield put({
        type: "SET_BULK_UPDATE_LOADING",
        value: true,
      });

      for (const payload of data) {
        const { child_id, schoolCode, grade, section } = payload;
        let grade_obj = grades.find(
          (gradeObj) => gradeObj.name === grade.trim()
        );
        let grade_id = grade_obj && grade_obj.id;

        let dataObj = {
          child_id,
          schoolCode,
          section,
          grade: grade_id,
        };

        dataArr.push(dataObj);
      }

      if (dataArr.length > 0) {
        let res = yield call(userApi.updateBulkChild, dataArr);
        console.log(res);

        if (res) {
          yield put({
            type: "SET_BULK_UPDATE_LOADING",
            value: false,
          });

          if (res.errors.length > 0) {
            swal(
              "Oops!",
              "There are some errors in the data. A file will get downloaded with all the errors.",
              "error"
            );
            handleDownload(res.errors);
          } else {
            swal("Success", "Users updated successfully!", "success");
          }
        }
      }
    } catch (err) {}
  },
});
